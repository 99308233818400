<template>
  <div>
    <!-- DISCUTIDOR -->
    <div v-if="userCongresses.length > 0">
      Certificados como Participante
    </div>
    <div class="ibox-content p-md row">
      <b-card
        v-for="(d, index) in userCongresses"
        :key="index"
        style="border: 1px solid #ccc; margin: 4px;padding: 2px;border-radius: 5px;"
      >
        <div>
          <h1 style="font-size: 15px;">
            {{ d.title }} - Participante
          </h1>
        </div>
        <div>
          <b-card-text>
            Certificado da atividade {{ d.title }}
          </b-card-text>
        </div>
        <div class="row">
          <a
            v-if="showDownload[index] == true"
            id="imge"
            variant="primary"
            class="btn btn-primary"
            style="margin: 0 2px; display:table;color:white;"
            :href="output[index]"
            :download="'certificado-' + d.title + '.png'"
          >

            Baixar certificado
            <feather-icon
              icon="DownloadIcon"
            />

          </a>
          <button
            v-if="showDownload[index] == false"
            variant="success"
            class="btn btn-success"
            style="margin: 0 2px; display:table;color:white;"
            :disabled="generating[index] || loading"
            @click="getCert(d.id, index)"
          >
            <b-overlay
              :show="generating[index]"
              rounded="sm"
            >
              Gerar certificado
              <feather-icon
                icon="AwardIcon"
              />
            </b-overlay>
          </button>
        </div>
      </b-card>
    </div>

    <!-- CERTIFICADO -->
    <div
      class="background"
    >
      <div
        id="image4"
        ref="printMe"
        class="certificate_body"
      >
        <div class="position_text">
          <div class="centered_text">
            <p class="first_text">
              CERTIFICAMOS QUE
            </p>
            <div class="user_name">
              {{ name.toUpperCase() }}
            </div>
            <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
            <p class="text_body">
              participou do Congresso Norte Nordeste de Oftalmologia 2021 Digital, na qualidade de {{ occupation }} congresso “{{ event_title }}”
            </p>
            <br><br>
            <p class="text_body_second">
              O evento é uma realização da Sociedade Norte Nordeste de Oftalmologia,
              promovido em ambiente 100% digital, dividido em duas etapas: de 21 de agosto a 15 de setempro e 24 de novembro a 11 de dezembro
            </p>
          </div>
        </div>
        <div class="validation_code">
          <p>Codigo de validação: {{ cod }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CongressServices from './congressServices'

export default {
  data() {
    return {
      userCongresses: [],
      loading: false,
      common: false,
      name: '',
      occupation: '',
      event_title: '',
      event_block_title: '',
      cod: '',
      hourly_load: '',
      output: [],
      showDownload: [],
      generating: [],
      imgReady: [],
    }
  },
  mounted() {
    CongressServices().getUserCongresses().then(api => {
      this.userCongresses = api.data.response.congresses
      console.log(this.userCongresses)
      this.userCongresses.forEach(() => {
        this.output.push(null)
        this.showDownload.push(false)
        this.generating.push(false)
        this.imgReady.push(false)
      })
    })
  },
  methods: {
    formatDate(data) {
      const dataDefault = data.split('-')

      const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]

      const mes = meses[dataDefault[1] - 1]

      return [dataDefault[2], 'de', mes, 'de', dataDefault[0]].join(' ')
    },
    async getCert(congress_id, i) {
      this.loading = true
      this.common = true
      try {
        const api = await CongressServices().getUserCertificate({ congress_id })
        console.log(api)

        this.name = api.data.response.name
        this.occupation = api.data.response.occupation
        this.event_title = api.data.response.event_title
        this.event_block_title = api.data.response.event_block_title
        this.cod = api.data.response.cod
        this.hourly_load = api.data.response.hourly_load
        this.print(i)
      } catch (err) {
        this.loading = false
        this.common = false
        console.log(err)
      }
    },
    async print(i) {
      this.generating.splice(i, 1, true)
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        allowTaint: false,
        useCORS: true,
      }
      this.output.splice(i, 1, await this.$html2canvas(
        document.getElementById('image4'),
        options
      ))
      this.output_img = new Image()
      this.output_img.src = this.output[i]
      this.output_img.width = 8419
      // eslint-disable-next-line no-self-assign
      this.output_img.width = this.output_img.width
      this.output_img.height = 5953
      // eslint-disable-next-line no-self-assign
      this.output_img.height = this.output_img.height
      console.log(this.output_img)
      this.output_img.onload = function () {}

      this.output.splice(i, 1, this.output_img.src)
      this.showDownload.splice(i, 1, true)
      this.generating.splice(i, 1, false)
      this.loading = false
    },
  }
}
</script>

<style scoped>
@font-face {
    font-family: 'gothampro-bold';
    src: url('./../../../assets/fonts/gotham/gothampro-bold.woff') format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: 'gothampro-light';
    src: url('./../../../assets/fonts/gotham/gothampro-light.woff') format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: 'gothampro-medium';
    src: url('./../../../assets/fonts/gotham/gothampro-medium.woff') format('woff2');
    font-weight: normal;
}
.background {
  position: relative;
}
.certificate_body {
  background-image: url('./../../../assets/images/certificate/cnno2021_certificado_a4.png');
  height: 5953px;
  width: 8419px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.position_text {
  padding-left: 4000px;
  padding-top: 1050px;
  max-height: 5700px;
  max-width: 8000px;
}
.centered_text {
  text-align: center;
}
.first_text {
  font-size: 170px;
  height: 450px;
  font-family: "gothampro-light" !important;
}
.user_name {
  font-size: 240px;
  height: 400px;
  font-family: "gothampro-bold" !important;
}
.text_body {
  font-size: 130px;
  height: 550px;
  line-height: 200px;
  font-family: "gothampro-medium" !important;
}
.validation_code {
  font-size: 80px;
  padding-top: 2650px;
}
.text_body_second {
  font-size: 100px;
  height: 750px;
  margin-top: 450px;
  line-height: 200px;
  font-family: "gothampro-medium";
}
#image4 {
  position: absolute;
  top: -100000000000px;
}
</style>
